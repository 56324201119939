<template>
  <Header />
  <ServiceProperty />
  <Service />
  <ServiceIcons />
  <Footer />
</template>
<script>
import Service from "@/components/Service/Automation/Service.vue";
import ServiceIcons from "@/components/Service/Automation/ServiceIcons.vue";
import ServiceProperty from "@/components/Service/Automation/ServiceProperty.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Service,
    ServiceIcons,
    ServiceProperty,
    Header,
    Footer,
  },
};
</script>
